import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";

import { toast, ToastContainer } from "react-toastify";
import Loader from "../../app/Loader";
import { kycInfo, uploadKyc, getUserInfo } from "../../app/api";

const UserKYC = ({ sm, updateSm }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [kycdata, setKycData] = useState({
    photo_verified: 0,
    photo_id_verified: 0,
    pan_verified: 0,
  });

  const kycInfoData = async () => {
    setLoading(true);
    let uid = localStorage.getItem("uid");

    let res = await kycInfo({
      uid: uid,
    });
    if (res.code == 200) {
      setKycData(res.data);
    }

    let res2 = await getUserInfo(uid);

    if (res2) {
      setUserCountry(res2.country);
    }

    setLoading(false);
  };

  const validateFile = (file) => {
    const ext = file.type.split("/")[1].toLowerCase();
    const size = Math.round(file.size / 1024); // size in KB

    // Check for valid extensions
    if (!["jpeg", "jpg", "png"].includes(ext)) {
      toast.error("You can upload jpg, png, and jpeg files only.", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      return false;
    }

    const maxSize = 300;
    if (size > maxSize) {
      toast.error(
        `Image file size is too large. Maximum file size is ${maxSize}kb`,
        {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        }
      );
      return false;
    }

    return true;
    return true;
  };

  const uploadImage = async () => {
    if (isUploading) return;

    setIsUploading(true);

    const uid = localStorage.getItem("uid");
    const uinfo = await getUserInfo(uid);

    if (
      uinfo.city == null ||
      uinfo.address_line1 == null ||
      !uinfo.city.trim() ||
      !uinfo.address_line1.trim()
    ) {
      toast.error("Please update your profile first", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      setFile("");
      setFile2("");
      setFile3("");
      setIsUploading(false);
      return;
    }

    if (localStorage.getItem("mail_verified") === "0") {
      toast.error("Please verify your email id first", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      setFile("");
      setFile2("");
      setFile3("");
      setIsUploading(false);
      return;
    }


    setLoading(true);

    const form = new FormData();
    let hasError = false;

    // Handle photo upload
    if (file) {
      const ext = file.type.split("/")[1].toLowerCase();
      const size = Math.round(file.size / 1024);

      if (["jpeg", "jpg", "png"].includes(ext) && size <= 300) {
        form.append("user_photo", file, file.name);
        kycdata.photo_verified = 1;
      } else {
        hasError = true;
        toast.error("Image file size is too large or invalid format.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    }

    // Handle photo ID upload
    if (file2) {
      const ext = file2.type.split("/")[1].toLowerCase();
      const size = Math.round(file2.size / 1024);

      if (["jpeg", "jpg", "png"].includes(ext) && size <= 300) {
        form.append("user_photo_id", file2, file2.name);
        kycdata.photo_id_verified = 1;
      } else {
        hasError = true;
        toast.error("Image file size is too large or invalid format.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    }

    // Handle PAN card upload
    if (file3) {
      const ext = file3.type.split("/")[1].toLowerCase();
      const size = Math.round(file3.size / 1024);

      if (["jpeg", "jpg", "png"].includes(ext) && size <= 300) {
        form.append("user_pan", file3, file3.name);
        kycdata.pan_verified = 1;
      } else {
        hasError = true;
        toast.error("Image file size is too large or invalid format.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    }

    if (!hasError) {
      form.append("uid", uid);

      const res = await uploadKyc(form);
      if (res.code === 200) {
        toast.success("KYC Detail Uploaded Successfully.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        // Reset files after successful upload
        setFile("");
        setFile2("");
        setFile3("");
      }
    }

    setLoading(false);
    setIsUploading(false); // Reset uploading flag
  };

  useEffect(() => {
    kycInfoData();
  }, []);
  return (
    <React.Fragment>
      <Head title="KYC Document"></Head>

      <BlockHead size="lg">
        <Loader visible={loading} />
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">KYC Document</BlockTitle>
            <BlockDes>
              <p>Please Complete KYC to Receive Payments</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""
                }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            {kycdata.photo_verified == 1 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo Uploaded</h6>
                    <p>Your Photo uploaded and waiting for approval</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="warning">
                          Waiting for approval
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : kycdata.photo_verified == 2 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo Uploaded</h6>
                    <p>Your Photo uploaded and accepted</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="success">
                          Accepted
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Upload Photo</h6>
                    <p>
                      {kycdata.photo_verified == 3 ? (
                        <div
                          className="alert alert-danger alert-dim mr-4 p-0 px-1 fs-5"
                          style={{ fontSize: 12, border: "none" }}
                          color="danger"
                        >
                          Your document rejected please upload again.
                          {kycdata.user_photo_remark &&
                            " Reason - " + kycdata.user_photo_remark}
                        </div>
                      ) : (
                        "Upload your passport size photo"
                      )}
                    </p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        {kycdata.user_photo && (
                          <a
                            href={kycdata.user_photo}
                            target="_blank"
                            className="btn btn-primary mt-2 mr-2"
                          >
                            <Icon name="eye" /> &nbsp;Preview
                          </a>
                        )}
                        <a href="javascript:;" className="btn btn-primary mt-2">
                          <input
                            type="file"
                            className="sm-input"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                if (validateFile(file)) {
                                  // Handle file here, e.g.,
                                  setFile(file);
                                  console.log("File accepted:", file);
                                }
                                // Reset the file input value to allow the same file to be selected again
                                e.target.value = null;
                              }
                            }}
                            accept="application/pdf, image/*"
                          />

                          {file !== "" ? "1 file selected" : "Upload Photo"}
                        </a>
                        {/* <Button color="primary">Upload Photo</Button> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {kycdata.photo_id_verified == 1 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo ID Uploaded</h6>
                    <p>Your photo id uploaded and waiting for approval</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="warning">
                          Waiting for approval
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : kycdata.photo_id_verified == 2 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo Id Uploaded</h6>
                    <p>Photo id uploaded and accepted</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="success">
                          Accepted
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Upload Photo ID</h6>
                    <p>
                      {kycdata.photo_id_verified == 3 ? (
                        <div
                          className="alert alert-danger alert-dim mr-4 p-0 px-1 fs-5"
                          style={{ fontSize: 12, border: "none" }}
                          color="danger"
                        >
                          Your document rejected please upload again.
                          {kycdata.user_photo_id_remark &&
                            " Reason - " + kycdata.user_photo_id_remark}
                        </div>
                      ) : (
                        "A government issued photo id proof"
                      )}
                    </p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        {kycdata.user_photo_id && (
                          <a
                            href={kycdata.user_photo_id}
                            target="_blank"
                            className="btn btn-primary mt-2 mr-2"
                          >
                            <Icon name="eye" /> &nbsp;Preview
                          </a>
                        )}
                        <a href="javascript:;" className="btn btn-primary mt-2">
                          <input
                            type="file"
                            className="sm-input"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                if (validateFile(file)) {
                                  // Handle file here, e.g.,
                                  setFile2(file);
                                  console.log("File accepted:", file);
                                }
                                // Reset the file input value to allow the same file to be selected again
                                e.target.value = null;
                              }
                            }}
                            accept="application/pdf, image/*"
                          />
                          {file2 !== "" ? "1 file selected" : "Upload Photo ID"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {/* PAN Card Section */}
            {userCountry.toLowerCase() === "india" &&
              (kycdata.pan_verified == 1 ? (
                <div className="card-inner">
                  <div className="between-center flex-wrap g-3">
                    <div className="nk-block-text">
                      <h6>PAN Card Uploaded</h6>
                      <p>Your PAN Card uploaded and waiting for approval</p>
                    </div>
                    <div className="nk-block-actions flex-shrink-sm-0">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                        <li className="order-md-last">
                          <Badge className="badge-dim" color="warning">
                            Waiting for approval
                          </Badge>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : kycdata.pan_verified == 2 ? (
                <div className="card-inner">
                  <div className="between-center flex-wrap g-3">
                    <div className="nk-block-text">
                      <h6>PAN Card Uploaded</h6>
                      <p>Your PAN Card uploaded and accepted</p>
                    </div>
                    <div className="nk-block-actions flex-shrink-sm-0">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                        <li className="order-md-last">
                          <Badge className="badge-dim" color="success">
                            Accepted
                          </Badge>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-inner">
                  <div className="between-center flex-wrap g-3">
                    <div className="nk-block-text">
                      <h6>Upload PAN Card</h6>
                      <p>
                        {kycdata.pan_verified == 3 ? (
                          <div
                            className="alert alert-danger alert-dim mr-4 p-0 px-1 fs-5"
                            style={{ fontSize: 12, border: "none" }}
                            color="danger"
                          >
                            Your document rejected please upload again.
                            {kycdata.user_pan_remark &&
                              " Reason - " + kycdata.user_pan_remark}
                          </div>
                        ) : (
                          "Upload your PAN Card"
                        )}
                      </p>
                    </div>
                    <div className="nk-block-actions flex-shrink-sm-0">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                        <li className="order-md-last">
                          {kycdata.user_pan && (
                            <a
                              href={kycdata.user_pan}
                              target="_blank"
                              className="btn btn-primary mt-2 mr-2"
                            >
                              <Icon name="eye" /> &nbsp;Preview
                            </a>
                          )}
                          <a
                            href="javascript:;"
                            className="btn btn-primary mt-2"
                          >
                            <input
                              type="file"
                              className="sm-input"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  if (validateFile(file)) {
                                    // Handle file here, e.g.,
                                    setFile3(file);
                                    console.log("File accepted:", file);
                                  }
                                  // Reset the file input value to allow the same file to be selected again
                                  e.target.value = null;
                                }
                              }}
                              accept="application/pdf, image/*"
                            />

                            {file3 !== ""
                              ? "1 file selected"
                              : "Upload PAN Card"}
                          </a>
                          {/* <Button color="primary">Upload Photo</Button> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Card>
        {/* <div className="mt-3">
          {kycdata.photo_id_verified == 0 && kycdata.photo_verified == 0 && (
            <Button
              color="primary"
              disabled={file == "" && file2 == "" ? true : false}
              onClick={() => {
                if (file !== "" || file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          )}
          {kycdata.photo_verified == 3 || kycdata.photo_id_verified == 3 ? (
            <Button
              color="primary"
              disabled={file == "" && file2 == "" ? true : false}
              onClick={() => {
                if (kycdata.photo_verified) {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}
          {kycdata.photo_verified == 0 && kycdata.photo_id_verified == 1 ? (
            <Button
              color="primary"
              disabled={file == "" ? true : false}
              onClick={() => {
                if (file !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}
          {kycdata.photo_id_verified == 0 && kycdata.photo_verified == 1 ? (
            <Button
              color="primary"
              disabled={file2 == "" ? true : false}
              onClick={() => {
                if (file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}

          {kycdata.photo_id_verified == 0 && kycdata.photo_verified == 2 ? (
            <Button
              color="primary"
              disabled={file2 == "" ? true : false}
              onClick={() => {
                if (file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}

          {kycdata.photo_id_verified == 2 && kycdata.photo_verified == 0 ? (
            <Button
              color="primary"
              disabled={file2 == "" ? true : false}
              onClick={() => {
                if (file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}
        </div> */}
        <div className="mt-3">
        <Button
            color="primary"
            disabled={
              isUploading ||
              !(
                ((kycdata.photo_verified === 3 ||
                  kycdata.photo_id_verified === 3 ||
                  kycdata.pan_verified === 3) &&
                  (file !== "" || file2 !== "" || file3 !== "")) ||
                (kycdata.photo_verified === 0 &&
                  kycdata.photo_id_verified === 1 &&
                  file !== "") || // Photo needed when ID is pending
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 1 &&
                  file2 !== "") || // ID needed when photo is pending
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 2 &&
                  file2 !== "") || // ID needed when photo is accepted
                (kycdata.photo_id_verified === 2 &&
                  kycdata.photo_verified === 0 &&
                  file !== "") || // Photo needed when ID is accepted
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 0 &&
                  (file !== "" || file2 !== "")) || // Both photo and ID needed
                (kycdata.pan_verified === 3 && file3 !== "") || // Re-upload PAN if rejected
                (kycdata.pan_verified === 0 && file3 !== "") || // Upload PAN if not uploaded
                (kycdata.pan_verified === 2 &&
                  kycdata.photo_id_verified === 2 &&
                  kycdata.photo_verified === 0 &&
                  file !== "") // Photo needed when PAN and ID are accepted
              )
            }
            onClick={async () => {
              if (
                ((kycdata.photo_verified === 3 ||
                  kycdata.photo_id_verified === 3 ||
                  kycdata.pan_verified === 3) &&
                  (file !== "" || file2 !== "" || file3 !== "")) ||
                (kycdata.photo_verified === 0 &&
                  kycdata.photo_id_verified === 1 &&
                  file !== "") || // Photo needed when ID is pending
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 1 &&
                  file2 !== "") || // ID needed when photo is pending
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 2 &&
                  file2 !== "") || // ID needed when photo is accepted
                (kycdata.photo_id_verified === 2 &&
                  kycdata.photo_verified === 0 &&
                  file !== "") || // Photo needed when ID is accepted
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 0 &&
                  (file !== "" || file2 !== "")) || // Both photo and ID needed
                (kycdata.pan_verified === 3 && file3 !== "") || // Re-upload PAN if rejected
                (kycdata.pan_verified === 0 && file3 !== "") || // Upload PAN if not uploaded
                (kycdata.pan_verified === 2 &&
                  kycdata.photo_id_verified === 2 &&
                  kycdata.photo_verified === 0 &&
                  file !== "") // Photo needed when PAN and ID are accepted
              ) {
                await uploadImage();
              }
            }}
          >
            Submit KYC Documents
          </Button>

        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserKYC;
